import { Outlet } from "react-router-dom";
import LeftSidebar from "../components/potree/project/LeftSidebar";
import RightSidebar from "../components/potree/project/RightSidebar";
import TabsBar from "../components/potree/project/TabsBar";
import Footer from "../components/potree/shared/Footer";

const PotreeLayout: React.FC = () => {
  return (
    <>
      <LeftSidebar />

      <main className="h-screen flex items-center justify-center bg-white project-layout">
        <Outlet />
      </main>

      <RightSidebar />

      <TabsBar />

      <Footer />
    </>
  );
};

export default PotreeLayout;
