import { toast } from "react-toastify";
import { ReactNode, createContext, useEffect, useState } from "react";
import {
  AuthContextType,
  LoginFormFields,
  LogoutAction,
  UserInfo,
} from "../types";
import { authTokenHeader } from "../utils/helper";

export const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [token, setToken] = useState<string | null>(() => {
    const storedToken = localStorage.getItem("token");
    return storedToken ?? null;
  });

  // Update localStorage whenever token changes i.e. on logout
  useEffect(
    () =>
      token
        ? localStorage.setItem("token", token)
        : localStorage.removeItem("token"),
    [token]
  );

  const login = async ({ username, password }: LoginFormFields) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/login`,
        {
          method: "POST",
          body: JSON.stringify({
            username,
            password,
          }),
        }
      );

      const data = await response.json();

      if (response.ok && data.token) {
        // Verify token by fetching user data
        const getUserResponse = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/info`,
          {
            method: "POST",
            headers: authTokenHeader(data.token),
          }
        );

        const userInfo: UserInfo = await getUserResponse.json();

        if (userInfo.authenticated) {
          toast.success("Login successfully.");
          return setToken(data.token);
        }

        toast.error("Login failed.");
      }

      if (response.status === 401) {
        toast.error(data.error);
        return;
      }

      toast.error(data.error);
    } catch (error: any) {
      console.log("API request failed:", error.message);
      throw Error(error.message);
    }
  };

  const logout = ({ autoLogout = false, autoLogoutMessage }: LogoutAction) => {
    setToken(null);

    autoLogout
      ? toast.info(autoLogoutMessage || "Auto Logout.")
      : toast.success("Logout successfully.");
  };

  return (
    <AuthContext.Provider value={{ token, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
