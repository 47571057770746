import { useContext } from "react";
import { PotreeContext } from "../context/PotreeProvider";

export const usePotree = () => {
  const context = useContext(PotreeContext);

  if (!context) {
    throw new Error("usePotree must be used within an PotreeProvider");
  }

  return context;
};
