import { Link } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import Container from "../shared/Container";
import OrtoLogo from "../shared/OrtoLogo";

const Footer = () => {
  const { token } = useAuth();

  if (!token) return null;

  return (
    <footer>
      <Container>
        <div className="flex items-center w-full h-16">
          <Link to="/">
            <OrtoLogo hoverColorCode="#000000" />
          </Link>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
