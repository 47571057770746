import { ChangeEvent, KeyboardEvent } from "react";
import Error from "./Error";

interface InputProps {
  name: string;
  value: string;
  onInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
  type?: "text" | "password";
  error?: string | null;
  placeholder?: string;
  classNames?: string;
  width?: "full" | "fixed";
}

const Input: React.FC<InputProps> = ({
  name,
  type = "text",
  value,
  onInputChange,
  handleKeyDown,
  error,
  placeholder,
  classNames,
  width = "fixed",
}) => {
  return (
    <div className="relative flex flex-col gap-1">
      <input
        name={name}
        value={value}
        onChange={onInputChange}
        onKeyDown={handleKeyDown}
        type={type}
        placeholder={placeholder}
        className={`
          bg-gray 
          rounded-lg 
          h-[44px] 
          p-4 
          outline-none 
          ${width === "fixed" ? "w-[276px]" : "w-full"} 
          ${classNames ?? ""}
        `}
      />
      <Error message={error ?? ""} />
    </div>
  );
};

export default Input;
