import { PotreeDisplayModes } from "../types";

export const POTREE_DISPLAY_MODES: {
  label: string;
  value: PotreeDisplayModes;
  action: string;
}[] = [
  {
    label: "Modell",
    value: "model",
    action: "showModel",
  },
  {
    label: "Grundriss",
    value: "ground_view",
    action: "showGrundriss",
  },
  {
    label: "Schnitt",
    value: "cutting",
    action: "showSchnitt",
  },
];
