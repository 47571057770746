import React, { createContext, ReactNode, useState } from "react";
import Button from "../components/shared/Button";

interface ConfirmationContextProps {
  showConfirmation: (message: string, onConfirm: () => void) => void;
}

export const ConfirmationContext = createContext<
  ConfirmationContextProps | undefined
>(undefined);

export const ConfirmationProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [confirmation, setConfirmation] = useState<{
    message: string;
    onConfirm: () => void;
  } | null>(null);

  const showConfirmation = (message: string, onConfirm: () => void) => {
    setConfirmation({ message, onConfirm });
  };

  const confirm = () => {
    confirmation?.onConfirm();
    setConfirmation(null);
  };

  const cancel = () => {
    setConfirmation(null);
  };

  return (
    <ConfirmationContext.Provider value={{ showConfirmation }}>
      {children}
      {confirmation && (
        <div
          className="absolute top-0 right-0 bottom-0 left-0 z-50 w-full h-full backdrop-blur-sm"
          style={{ background: "rgba(178, 178, 178, 0.3)" }}
        >
          <div className="flex flex-col gap-4 fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 min-w-[300px] max-w-[600px] z-50 h-auto overflow-auto p-8 rounded-lg shadow-xl bg-white text-primary">
            <p>{confirmation.message}</p>
            <div className="flex flex-row justify-end gap-4 mt-8">
              <Button handleClick={cancel} label="cancel" />
              <Button handleClick={confirm} label="confirm" />
            </div>
          </div>
        </div>
      )}
    </ConfirmationContext.Provider>
  );
};
