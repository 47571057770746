interface ErrorProps {
  message: string;
  classNames?: string;
}

const Error: React.FC<ErrorProps> = ({ message, classNames }) => (
  <p className={`text-red-500 text-sm-regular h-5 px-4 ${classNames ?? ""}`}>
    {message ?? null}
  </p>
);

export default Error;
