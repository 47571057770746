import { IconType } from "react-icons";
import { useTranslation } from "react-i18next";
import { GrDown, GrUp } from "react-icons/gr";
import { ReactNode, useState } from "react";
import { cn } from "../../../utils/helper";

interface AccordionProps {
  label: string;
  body: ReactNode;
  defaultOpen?: boolean;
  classNames?: string;
  headerClassNames?: string;
  labelColor?: string;
  openAccordionIcon?: IconType;
  closeAccordionIcon?: IconType;
}

const Accordion: React.FC<AccordionProps> = ({
  label,
  body,
  classNames,
  headerClassNames,
  defaultOpen = false,
  labelColor,
  openAccordionIcon: IconOpen,
  closeAccordionIcon: IconClose,
}) => {
  const { t } = useTranslation();
  const [isAccordionOpen, setIsAccordionOpen] = useState(defaultOpen);

  const open = IconOpen ? (
    <IconOpen size={24} className={cn(labelColor)} />
  ) : (
    <GrUp size={24} className={cn(labelColor)} />
  );
  const close = IconClose ? (
    <IconClose size={24} className={cn(labelColor)} />
  ) : (
    <GrDown size={24} className={cn(labelColor)} />
  );

  return (
    <section className={cn("text-primary flex flex-col gap-4", classNames)}>
      {/* Accordion Header */}
      <div
        className={cn("flex items-center justify-between cursor-pointer", headerClassNames)}
        onClick={() => setIsAccordionOpen(() => !isAccordionOpen)}
      >
        <p className={cn("capitalize text-base-regular", labelColor)}>
          {t(`${label}`)}
        </p>
        <div>{isAccordionOpen ? open : close}</div>
      </div>

      {/* Accordion Body */}
      <div
        className={cn("transition-all duration-300 ease-in-out", isAccordionOpen ? "max-h-screen" : "max-h-0 overflow-hidden")}
      >
        <div>{body}</div>
      </div>
    </section>
  );
};

export default Accordion;
