import { ToastContainer } from "react-toastify";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";
import "./global.css";

// Context providers
import { AuthProvider } from "./context/AuthProvider";
import { ConfirmationProvider } from "./context/ConfirmationProvider";
import { ToggleProvider } from "./context/ToggleProvider";
import { PotreeProvider } from "./context/PotreeProvider";

// Pages
import Home from "./pages/Home";
import Dashboard from "./pages/Dashboard";
import Project from "./pages/Project";
import AddProject from "./pages/AddProject";
import EditProject from "./pages/EditProject";
import NotFound from "./pages/NotFound";
import ProtectedRoute from "./components/shared/ProtectedRoute";
import Error from "./components/shared/Error";

// Layouts
import AdminLayout from "./layouts/AdminLayout";
import PotreeLayout from "./layouts/ProjectLayout";

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<AdminLayout />}>
        <Route index element={<Home />} />

        <Route
          element={<ProtectedRoute />}
          errorElement={<Error message="Something went wrong." />}
        >
          <Route
            path="dashboard"
            element={<Dashboard />}
            errorElement={
              <Error message="Something went wrong to fetch projects data." />
            }
          />

          <Route path="project">
            <Route path="add" element={<AddProject />} />
            <Route
              path=":id/edit"
              element={<EditProject />}
              errorElement={
                <Error message="Something went wrong to fetch project data." />
              }
            />
          </Route>
        </Route>

        <Route path="*" element={<NotFound />} />
      </Route>

      <Route path="project">
        <Route
          element={
            <PotreeProvider>
              <ToggleProvider>
                <PotreeLayout />
              </ToggleProvider>
            </PotreeProvider>
          }
        >
          <Route path=":projectId" element={<Project />} />
          <Route
            path=":projectId/present/:presentationId"
            element={<Project />}
          />
        </Route>
      </Route>
    </>
  )
);

const App = () => {
  return (
    <AuthProvider>
      <ToastContainer position="bottom-left" />

      <ConfirmationProvider>
        <RouterProvider router={router} />
      </ConfirmationProvider>
    </AuthProvider>
  );
};

export default App;
