import { Link } from "react-router-dom";
import Wrapper from "./Wrapper";
import OrtoLogo from "../../shared/OrtoLogo";

const Footer = () => {
  return (
    <footer className="absolute left-6 bottom-6 z-40">
      <Link to="/">
        <OrtoLogo />
      </Link>
    </footer>
  );
};

export default Footer;
