interface OrtoLogoProps {
  colorCode?: string;
  hoverColorCode?: string;
}

const OrtoLogo: React.FC<OrtoLogoProps> = ({ colorCode, hoverColorCode }) => {
  const color = colorCode ? `text-[${colorCode.toString()}]` : "text-[#D7D7D7]";
  const hoverColor = hoverColorCode
    ? `group-hover:text-[${hoverColorCode.toString()}]`
    : "group-hover:text-[#151515]";

  return (
    <svg
      width="74"
      height="16"
      viewBox="0 0 74 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`group fill-current ${color} ${hoverColor}`}
    >
      <path
        d="M11.1129 15.3564C8.63303 15.3564 6.57032 15.1529 4.92481 14.7466C3.27929 14.3404 2.04713 13.6915 1.22832 12.8005C0.409441 11.9096 0 10.7375 0 9.28352C0 7.83024 0.409441 6.65445 1.22832 5.75539C2.04713 4.85634 3.27929 4.19999 4.92481 3.78635C6.57032 3.37198 8.63303 3.16479 11.1129 3.16479C13.5773 3.16479 15.6244 3.37198 17.2542 3.78635C18.8842 4.19999 20.1046 4.85634 20.9156 5.75539C21.7267 6.65445 22.1322 7.83024 22.1322 9.28352C22.1322 10.7375 21.7267 11.9096 20.9156 12.8005C20.1046 13.6915 18.8842 14.3404 17.2542 14.7466C15.6244 15.1529 13.5773 15.3564 11.1129 15.3564ZM11.1129 11.957C12.2671 11.957 13.2341 11.8593 14.014 11.664C14.7938 11.4679 15.3865 11.1711 15.7921 10.773C16.1976 10.3742 16.4004 9.8777 16.4004 9.28352C16.4004 8.69007 16.2014 8.18986 15.8037 7.78362C15.406 7.37738 14.8172 7.07251 14.0373 6.86902C13.2575 6.66627 12.2827 6.56417 11.1129 6.56417C9.95874 6.56417 8.98 6.66627 8.17677 6.86902C7.37354 7.07251 6.76525 7.37295 6.3519 7.77178C5.93862 8.17062 5.73194 8.67453 5.73194 9.28352C5.73194 9.8777 5.93471 10.3742 6.34023 10.773C6.74576 11.1711 7.35014 11.4679 8.15337 11.664C8.95667 11.8593 9.94316 11.957 11.1129 11.957Z"
        className={`fill-current ${hoverColor}`}
      />
      <path
        d="M24.0039 3.51636H29.6188V15.005H24.0039V3.51636ZM35.6551 7.16436C34.6413 7.16436 33.7131 7.25758 32.8708 7.44553C32.0286 7.63274 31.1968 7.95167 30.6541 8.30315C30.0833 8.67313 29.5887 9.23477 29.3849 9.56553L29.3381 8.44005C29.3849 8.11151 29.2104 7.73117 29.4609 7.31087C29.7155 6.88318 29.9365 6.63602 30.4209 6.14025C30.8313 5.72069 31.2984 5.30114 31.7976 4.88676C32.2967 4.47239 32.8699 4.16456 33.5172 3.90706C34.1644 3.64881 34.8783 3.52006 35.6581 3.52006L35.6551 7.16436Z"
        className={`fill-current ${hoverColor}`}
      />
      <path
        d="M36.8247 3.51629H50.581V7.03332H36.8247V3.51629ZM40.8955 0H46.6793V2.763L43.0243 3.53479L46.5102 7.04812V15.0049H40.8955V0Z"
        className={`fill-current ${hoverColor}`}
      />
      <path
        d="M62.981 15.3564C60.5007 15.3564 58.4384 15.1529 56.7925 14.7466C55.1474 14.3404 53.915 13.6915 53.0961 12.8005C52.2772 11.9096 51.8682 10.7375 51.8682 9.28352C51.8682 7.83024 52.2772 6.65445 53.0961 5.75539C53.915 4.85634 55.1474 4.19999 56.7925 3.78635C58.4384 3.37198 60.5007 3.16479 62.981 3.16479C65.445 3.16479 67.4926 3.37198 69.1222 3.78635C70.7519 4.19999 71.9725 4.85634 72.784 5.75539C73.5947 6.65445 74.0001 7.83024 74.0001 9.28352C74.0001 10.7375 73.5947 11.9096 72.784 12.8005C71.9725 13.6915 70.7519 14.3404 69.1222 14.7466C67.4926 15.1529 65.445 15.3564 62.981 15.3564ZM62.981 11.957C64.1351 11.957 65.1024 11.8593 65.8822 11.664C66.6619 11.4679 67.2548 11.1711 67.6602 10.773C68.0656 10.3742 68.2687 9.8777 68.2687 9.28352C68.2687 8.69007 68.0693 8.18986 67.672 7.78362C67.274 7.37738 66.6855 7.07251 65.9051 6.86902C65.1253 6.66627 64.1506 6.56417 62.981 6.56417C61.8269 6.56417 60.8478 6.66627 60.0452 6.86902C59.2418 7.07251 58.6333 7.37295 58.2198 7.77178C57.8063 8.17062 57.5996 8.67453 57.5996 9.28352C57.5996 9.8777 57.8026 10.3742 58.208 10.773C58.6134 11.1711 59.2181 11.4679 60.0215 11.664C60.8249 11.8593 61.8114 11.957 62.981 11.957Z"
        className={`fill-current ${hoverColor}`}
      />
    </svg>
  );
};

export default OrtoLogo;
