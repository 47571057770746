import { useTranslation } from "react-i18next";
import { Theme } from "../../types";
import { cn } from "../../utils/helper";

interface LabelProps {
  theme?: Theme;
  text: string;
  classNames?: string;
}

const Label: React.FC<LabelProps> = ({ theme, text, classNames }) => {
  const { t } = useTranslation();

  return (
    <label
      className={
        cn("capitalize text-base-regular",
          theme === "dark" ? "text-white" : "text-primary",
          classNames
        )
      }
    >
      {t(`${text}`)}
    </label>
  );
};

export default Label;
