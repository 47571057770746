import { ChangeEvent } from "react";

const Textarea = ({
  name,
  value,
  placeholder,
  handleInputChange,
}: {
  name: string;
  value: string;
  placeholder?: string;
  handleInputChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
}) => (
  <textarea
    rows={20}
    name={name}
    value={value}
    onChange={(e) => handleInputChange(e)}
    placeholder={placeholder || "Type..."}
    className="text-primary bg-[#EEEEEE] placeholder-[#999999] rounded-lg p-4 focus:outline-none"
  />
);

export default Textarea;
