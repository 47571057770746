import { Theme } from "../../types";

interface LoadingProps {
  text?: string;
  classNames?: string;
  theme?: Theme;
}

const Loading: React.FC<LoadingProps> = ({
  text,
  classNames,
  theme = "light",
}) => {
  const colorCode = theme === "light" ? "#000000" : "#FFFFFF";

  const Logo = (
    <svg
      className="animate-pulse"
      width="278"
      height="40"
      viewBox="0 0 278 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M41.7359 57.6727C32.4224 57.6727 24.6756 56.9084 18.4957 55.3828C12.3158 53.8571 7.68823 51.4199 4.61311 48.074C1.5377 44.728 0 40.3261 0 34.8653C0 29.4074 1.5377 24.9915 4.61311 21.615C7.68823 18.2386 12.3158 15.7735 18.4957 14.2201C24.6756 12.6638 32.4224 11.8857 41.7359 11.8857C50.991 11.8857 58.6792 12.6638 64.8003 14.2201C70.9217 15.7735 75.5052 18.2386 78.5512 21.615C81.5972 24.9915 83.1202 29.4074 83.1202 34.8653C83.1202 40.3261 81.5972 44.728 78.5512 48.074C75.5052 51.4199 70.9217 53.8571 64.8003 55.3828C58.6792 56.9084 50.991 57.6727 41.7359 57.6727ZM41.7359 44.9058C46.0706 44.9058 49.7023 44.539 52.6313 43.8054C55.56 43.0689 57.786 41.9545 59.309 40.4594C60.832 38.9616 61.5935 37.0969 61.5935 34.8653C61.5935 32.6366 60.8464 30.758 59.3528 29.2323C57.8592 27.7066 55.6479 26.5616 52.7189 25.7974C49.7902 25.0359 46.1291 24.6525 41.7359 24.6525C37.4012 24.6525 33.7254 25.0359 30.7088 25.7974C27.6922 26.5616 25.4077 27.69 23.8553 29.1878C22.3032 30.6857 21.527 32.5782 21.527 34.8653C21.527 37.0969 22.2885 38.9616 23.8115 40.4594C25.3345 41.9545 27.6043 43.0689 30.6209 43.8054C33.6378 44.539 37.3427 44.9058 41.7359 44.9058Z"
        fill={colorCode}
      />
      <path
        d="M90.1494 13.2057H111.237V56.3526H90.1494V13.2057ZM133.907 26.9063C130.099 26.9063 126.613 27.2564 123.45 27.9622C120.287 28.6653 117.163 29.8631 115.125 31.1831C112.981 32.5726 111.124 34.6819 110.358 35.9241L110.183 31.6973C110.358 30.4634 109.703 29.035 110.644 27.4565C111.6 25.8502 112.43 24.922 114.249 23.0601C115.791 21.4844 117.545 19.9087 119.42 18.3525C121.294 16.7962 123.447 15.6402 125.878 14.6731C128.309 13.7032 130.99 13.2197 133.918 13.2197L133.907 26.9063Z"
        fill={colorCode}
      />
      <path
        d="M138.3 13.2058H189.963V26.4144H138.3V13.2058ZM153.588 -6.10352e-05H175.31V10.3767L161.583 13.2753L174.675 26.47V56.3526H153.588V-6.10352e-05Z"
        fill={colorCode}
      />
      <path
        d="M236.533 57.6727C227.218 57.6727 219.472 56.9084 213.291 55.3828C207.112 53.8571 202.484 51.4199 199.409 48.074C196.333 44.728 194.797 40.3261 194.797 34.8653C194.797 29.4074 196.333 24.9915 199.409 21.615C202.484 18.2386 207.112 15.7735 213.291 14.2201C219.472 12.6638 227.218 11.8857 236.533 11.8857C245.786 11.8857 253.476 12.6638 259.597 14.2201C265.717 15.7735 270.301 18.2386 273.349 21.615C276.394 24.9915 277.916 29.4074 277.916 34.8653C277.916 40.3261 276.394 44.728 273.349 48.074C270.301 51.4199 265.717 53.8571 259.597 55.3828C253.476 56.9084 245.786 57.6727 236.533 57.6727ZM236.533 44.9058C240.867 44.9058 244.5 44.539 247.428 43.8054C250.357 43.0689 252.583 41.9545 254.106 40.4594C255.628 38.9616 256.391 37.0969 256.391 34.8653C256.391 32.6366 255.642 30.758 254.15 29.2323C252.655 27.7066 250.445 26.5616 247.514 25.7974C244.586 25.0359 240.925 24.6525 236.533 24.6525C232.198 24.6525 228.521 25.0359 225.507 25.7974C222.489 26.5616 220.204 27.69 218.651 29.1878C217.098 30.6857 216.322 32.5782 216.322 34.8653C216.322 37.0969 217.085 38.9616 218.607 40.4594C220.129 41.9545 222.401 43.0689 225.418 43.8054C228.435 44.539 232.14 44.9058 236.533 44.9058Z"
        fill={colorCode}
      />
    </svg>
  );

  return <div className={classNames}>{text ?? Logo}</div>;
};

export default Loading;
