import { useEffect, useState } from "react";

type ApiError = {
  message: string;
  status?: number;
};

interface FetchApiDataProps {
  url: string;
  method?: string;
  headers?: HeadersInit;
  body?: BodyInit | null;
}

interface FetchApiDataResult<T> {
  data: T | null;
  loading: boolean;
  error: ApiError | null;
}

const useApi = <T>({
  url,
  method = "GET",
  headers,
  body,
}: FetchApiDataProps): FetchApiDataResult<T> => {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<{
    message: string;
    status?: number;
  } | null>(null);

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;

    const fetchData = async () => {
      try {
        const response = await fetch(url, {
          method,
          headers,
          body,
          signal,
        });

        if (!response.ok) {
          setError(() => ({
            message: response.statusText,
            status: response.status,
          }));
          throw new Error(`${response.status}: ${response.statusText}`);
        }

        const responseData: T = await response.json();

        setData(responseData);
      } catch (error: any) {
        if (error.name === "AbortError") {
          // Request was aborted, no need to update state
          return;
        }

        setError(() => ({
          message: error.message,
        }));
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    return () => {
      abortController.abort();
    };
  }, []);

  return { data, loading, error };
};

export default useApi;
