import { toast } from "react-toastify";
import { BsArrowReturnLeft } from "react-icons/bs";
import { ChangeEvent, KeyboardEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LoginFormFields } from "../types";
import { useAuth } from "../hooks/useAuth";
import Input from "../components/shared/Input";

const DEFAULT_LOGIN_FORM_FIELD_VALUES = {
  username: "",
  password: "",
};

const Home: React.FC = () => {
  const navigate = useNavigate();
  const auth = useAuth();

  const [loginFormInput, setLoginFormInput] = useState<LoginFormFields>(
    DEFAULT_LOGIN_FORM_FIELD_VALUES
  );

  // Authenticated user redirect to /dashboard automatically
  useEffect(
    () => (auth.token ? navigate("/dashboard") : undefined),
    [auth.token, navigate]
  );

  // Change listener in user login for fields username/password
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) =>
    setLoginFormInput((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));

  // Handle enter key press on password field
  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      onUserLogin();
    }
  };

  // User login form submission handler
  const onUserLogin = async () => {
    try {
      await auth.login(loginFormInput);
      navigate("/dashboard");
    } catch (error: any) {
      toast.error("Login failed.");
    }
  };

  return (
    <section className="min-h-screen flex items-center justify-center">
      <div className="flex flex-col lg:flex-row items-center lg:justify-center lg:flex-wrap gap-8">
        <div className="flex flex-grow justify-center w-full">
          <img src="/assets/images/orto-white.svg" alt="orto site logo" />
        </div>

        <div className="flex flex-col lg:flex-row items-center lg:items-start gap-2 lg:gap-6 lg:mt-10">
          <Input
            name="username"
            value={loginFormInput.username}
            placeholder="Username"
            onInputChange={handleInputChange}
          />

          <Input
            name="password"
            value={loginFormInput.password}
            type="password"
            placeholder="Password"
            onInputChange={handleInputChange}
            handleKeyDown={handleKeyDown}
          />

          <button type="submit" onClick={onUserLogin}>
            <BsArrowReturnLeft size={40} />
          </button>
        </div>
      </div>
    </section>
  );
};

export default Home;
