import { useContext } from "react";
import { ToggleContext } from "../context/ToggleProvider";

export const useToggle = () => {
  const context = useContext(ToggleContext);

  if (!context) {
    throw new Error("useToggle must be used within an ToggleContext");
  }

  return context;
};
