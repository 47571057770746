import { ReactNode } from "react";

const Wrapper = ({
  children,
  classNames,
  styles,
  elementType = "div",
}: {
  children: ReactNode;
  classNames?: string;
  styles?: any;
  elementType?: "div" | "section" | "aside";
}) => {
  if (elementType === "section")
    return (
      <section className={classNames ?? ""} style={styles}>
        {children}
      </section>
    );

  if (elementType === "aside")
    return (
      <aside className={classNames ?? ""} style={styles}>
        {children}
      </aside>
    );

  return (
    <div className={classNames ?? ""} style={styles}>
      {children}
    </div>
  );
};

export default Wrapper;
