export const POTREE_PROJECT_MANAGE_FIELDS = {
  field_splat_quality: [
    {
      label: "Standard",
      value: "standard",
    },
    {
      label: "High quality",
      value: "hq",
    },
  ],
  field_sidebar: [
    {
      label: "Off",
      value: 0,
    },
    {
      label: "On",
      value: 1,
    },
  ],
  status: [
    {
      label: "Disabled",
      value: 0,
    },
    {
      label: "Enabled",
      value: 1,
    },
  ],
  field_tools: [
    {
      label: "Height measurement",
      value: "height",
    },
    {
      label: "Areat measurement",
      value: "area",
    },
    {
      label: "Horizontalt measurement",
      value: "horizontal",
    },
    {
      label: "Distance measurement",
      value: "distance",
    },
    {
      label: "Remove measurements",
      value: "remove",
    },
  ],
};
