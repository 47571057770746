import { useLocation, Navigate, Outlet } from "react-router-dom";
import { isJwtExpired } from "../../utils/helper";
import { useAuth } from "../../hooks/useAuth";
import { useEffect } from "react";

const ProtectedRoute = () => {
  const location = useLocation();
  const { token, logout } = useAuth();

  useEffect(() => {
    // If jwt is expired, logout the user
    if (token && isJwtExpired(token))
      logout({
        autoLogout: true,
        autoLogoutMessage: "Token expired, Auto logout.",
      });
  }, [location, token]);

  return !token ? (
    <Navigate to="/" state={{ from: location }} replace />
  ) : (
    <Outlet />
  );
};

export default ProtectedRoute;
