import { usePotree } from "../hooks/usePotree";
import Loading from "../components/shared/Loading";
import Error from "../components/shared/Error";
import ProjectViewer from "../components/potree/project/ProjectViewer";

const Project = () => {
  const { project, loading, error } = usePotree();

  if (loading) return <Loading theme="light" />;

  if (error) return <Error message={error.message} />;

  if (!project) return null;

  return <ProjectViewer />;
};

export default Project;
