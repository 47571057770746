import React, { ChangeEvent, InputHTMLAttributes } from "react";
import Error from "../../shared/Error";

type InputProps = {
  name: string;
  value: string;
  placeholder?: string;
  error?: string;
  handleInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
  classNames?: string;
} & InputHTMLAttributes<HTMLInputElement>;

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      name,
      value,
      error,
      handleInputChange,
      placeholder,
      classNames,
      ...props
    },
    ref
  ) => (
    <div className="flex flex-col gap-1">
      <input
        type="text"
        name={name}
        value={value}
        placeholder={placeholder || "Type..."}
        onChange={(e) => handleInputChange(e)}
        className={`text-primary bg-[#EEEEEE] placeholder-[#999999] rounded-lg h-10 p-4 focus:outline-none ${
          classNames ?? ""
        }`}
        ref={ref}
        {...props}
      />
      {error && <Error message={error} />}
    </div>
  )
);

export default Input;
