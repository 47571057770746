import { usePotree } from "../../../hooks/usePotree";
import Wrapper from "../shared/Wrapper";
import SceneTypesSidebar from "../scenes/SceneTypesSidebar";

const RightSidebar = () => {
  const { potreeViewer } = usePotree();

  if (!potreeViewer) return null;

  return (
    <Wrapper elementType="aside">
      <SceneTypesSidebar />
    </Wrapper>
  );
};

export default RightSidebar;
