import uniqid from "uniqid";
import { GoPlus } from "react-icons/go";
import { IoCloseOutline } from "react-icons/io5";
import { Accept, FileError, FileRejection, useDropzone } from "react-dropzone";
import { useCallback, useEffect, useState } from "react";

export interface UploadableFile {
  id: string;
  file: File;
  errors: FileError[];
  url?: string;
}

const FileUpload = ({
  handleUploadedFiles,
  allowedFileType,
  maxFiles,
  maxSize,
}: {
  handleUploadedFiles: (files: UploadableFile[]) => void;
  allowedFileType?: Accept;
  maxFiles?: number;
  maxSize?: number;
}) => {
  const [files, setFiles] = useState<UploadableFile[]>([]);
  const accept = allowedFileType ?? undefined;

  const onDrop = useCallback((accFiles: File[], rejFiles: FileRejection[]) => {
    const newAccFiles = accFiles.map((file) => ({
      id: uniqid(),
      file,
      errors: [],
    }));
    const newRejFiles = rejFiles.map((rejFile) => ({
      ...rejFile,
      id: uniqid(),
    }));

    setFiles((prevFiles) => [...prevFiles, ...newAccFiles, ...newRejFiles]);
  }, []);

  const onDelete = (deletedFile: File) =>
    setFiles((currentUploadedFiles) =>
      currentUploadedFiles.filter((f) => f.file !== deletedFile)
    );

  const onUpload = (uploadedFile: File, url: string) => {
    setFiles((currentUploadedFiles) =>
      currentUploadedFiles.map((f) => {
        if (f.file === uploadedFile) {
          return { ...f, url };
        }

        return f;
      })
    );
  };

  useEffect(() => {
    handleUploadedFiles(files);
  }, [files]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept,
    maxFiles,
    maxSize,
  });

  return (
    <div className="flex flex-col">
      {files.length > 0 ? (
        <div className="mb-16">
          {files.map((fileWrapper) => (
            <UploadedFileDisplay
              key={fileWrapper.id}
              file={fileWrapper.file}
              fileUploadErrors={fileWrapper.errors}
              onDelete={onDelete}
              onUpload={onUpload}
            />
          ))}
        </div>
      ) : null}

      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <div className="flex flex-col items-center gap-4">
          <GoPlus size={64} />
          <p className="text-secondary">Add point cloud</p>
        </div>
      </div>
    </div>
  );
};

export default FileUpload;

// Display Uploaded files list
export interface UploadedFileDisplayProps {
  file: File;
  fileUploadErrors: FileError[];
  onDelete: (file: File) => void;
  onUpload: (file: File, url: string) => void;
}

const UploadedFileDisplay: React.FC<UploadedFileDisplayProps> = ({
  file,
  fileUploadErrors,
  onDelete,
  onUpload,
}) => {
  const [progress, setProgress] = useState(50);

  useEffect(() => {
    const upload = async () => {
      const url = await cloudinaryFileUploader(file, setProgress);
      onUpload(file, url);
    };

    upload();
  }, []);

  return (
    <div className="group/item relative w-full flex flex-row flex-wrap items-center justify-between gap-6 pb-4">
      <FileUploadProgressBar
        progress={progress}
        isSuccessUpload={fileUploadErrors.length === 0}
      />
      <UploadedFileDisplayHeader
        filename={file.name}
        handleFileDelete={() => onDelete(file)}
      />
      <FileUploadErrors fileUploadErrors={fileUploadErrors} />
    </div>
  );
};

// Display each file upload progress bar
const FileUploadProgressBar = ({
  progress,
  isSuccessUpload,
}: {
  progress: number;
  isSuccessUpload: boolean;
}) => (
  <div className={`absolute top-10 w-full border-b border-gray`}>
    <span
      className={`flex -mb-[1px] h-[1px] ${
        isSuccessUpload ? "bg-white" : "bg-red-500"
      }`}
      style={{ width: `${isSuccessUpload ? progress : 100}%` }}
    ></span>
  </div>
);

// Display each file name and delete button
const UploadedFileDisplayHeader = ({
  filename,
  handleFileDelete,
}: {
  filename: string;
  handleFileDelete: () => void;
}) => (
  <>
    <p className="group-hover/item:text-secondary max-w-[80%] text-ellipsis overflow-hidden ... whitespace-nowrap">
      {filename}
    </p>
    <div className="group/delete cursor-pointer" onClick={handleFileDelete}>
      <IoCloseOutline
        size={24}
        className="group-hover/item:text-secondary group-hover/delete:text-red-500"
      />
    </div>
  </>
);

// Display each file upload errors
const FileUploadErrors = ({
  fileUploadErrors,
}: {
  fileUploadErrors: FileError[];
}) => (
  <ul className="flex flex-col w-full gap-1 text-red-500">
    {fileUploadErrors.map((fileError) => (
      <li key={`${uniqid()}_${fileError.code}`}>{fileError.message}</li>
    ))}
  </ul>
);

// Upload file to cloudinary
const cloudinaryFileUploader = (
  file: File,
  onProgress: (percentage: number) => void
) => {
  const url = "https://api.cloudinary.com/v1_1/dvb6flco7/auto/upload";
  const key = "nkgjmexx";

  return new Promise<string>((res, rej) => {
    const xhr = new XMLHttpRequest();
    xhr.open("POST", url);

    xhr.onload = () => {
      const resp = JSON.parse(xhr.responseText);
      res(resp.secure_url);
    };

    xhr.onerror = (evt) => rej(evt);

    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const percentage = (event.loaded / event.total) * 100;
        onProgress(Math.round(percentage));
      }
    };

    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", key);

    xhr.send(formData);
  });
};
