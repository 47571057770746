import { useTranslation } from "react-i18next";
import { DEFAULTS } from "../../../constants/defaults";
import { useToggle } from "../../../hooks/useToggle";

interface ToolMenuProps {
  menuLabel: string;
  children: React.ReactNode;
  classNames?: string;
}

const ToolMenu: React.FC<ToolMenuProps> = ({
  menuLabel,
  children,
  classNames,
}) => {
  const { t } = useTranslation();
  const { potreeDisplayMode } = useToggle();

  const theme =
    potreeDisplayMode === DEFAULTS.potreeDisplayTab ? "light" : "dark";

  return (
    <div
      className={`shadow-2xl transition-all flex flex-col items-center gap-1 ${
        classNames ?? ""
      }`}
    >
      <p
        className='capitalize text-[12px] whitespace-nowrap text-lgray'
      >
        {menuLabel && t(menuLabel)}
      </p>
      <div
        className={`gap-1 px-2 rounded-xl overflow-hidden flex flex-row 
        ${theme === "light" ? "bg-primary" : "bg-white"} 
        `}
      >
        {children}
      </div>
    </div>
  );
};

export default ToolMenu;
