import { usePotree } from "../../../hooks/usePotree";
import Wrapper from "../shared/Wrapper";
import SceneType from "./SceneType";

const SceneTypesSidebar = () => {
  const { project } = usePotree();
  const sceneTypes = project?.field_scenes ?? null;

  return (
    <Wrapper
      elementType="section"
      classNames="w-[240px] z-30 text-primary fixed top-0 right-6 max-h-screen overflow-y-auto"
    >
      <Wrapper classNames="flex flex-col gap-4 h-full pt-6">
        {sceneTypes &&
          Object.entries(sceneTypes).map(
            ([sceneTypeKey, { label, scenes }]) => (
              <SceneType
                key={`scene-type__${sceneTypeKey}`}
                sceneTypeKey={sceneTypeKey}
                sceneTypeLabel={sceneTypeKey}
                sceneTypeScenes={scenes}
              />
            )
          )}
      </Wrapper>
    </Wrapper>
  );
};

export default SceneTypesSidebar;
